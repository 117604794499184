import * as React from "react"
import { Header } from "../layout/header"
import '../index.scss';
//import './sites/all/themes/tickets/js/estacas/form_tickets_rd.js';
import '../sites/all/themes/tickets/js/estacas/map.js';
import '../sites/all/themes/tickets/js/estacas/cards_rd'
//import './sites/all/themes/tickets/js/estacas/stickyfloat'
import '../sites/all/themes/tickets/js/estacas/eventos'
import '../sites/all/themes/tickets/js/estacas/form_tickets_rd'
//import './stylesheets/tickets.css'
//import './sites/all/themes/tickets/js/estacas/header_rd';
import '../sites/all/themes/tickets/js/estacas/instagram_posts';
import '../sites/all/themes/tickets/js/estacas/header_rd';
import '../sites/all/themes/tickets/js/_analyticstagmanager';
import { PlantillaReserva } from "../layout/plantilla_reserva";
import CustomDateRangePickerDay from "../components/calendario_reserva";
import { Helmet } from "react-helmet"

// markup
const ReservaPage = () => {
        return (
                <body className={'html front not-logged-in no-sidebars page-node page-node- page-node-1612 node-type-page lang-es'}>
                        <Helmet>
                                <link rel="preconnect" href="https://fonts.gstatic.com/" />
                                <link rel="preload" href="https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700&display=swap" rel='stylesheet' type='text/css' />
                                <link rel="preload" href="https://fonts.googleapis.com/css?family=Prompt:100,200,300,400,500,600,700|Work+Sans:100,200,300,400,500,600,700&display=swap" rel='stylesheet' type='text/css' />


                        </Helmet>
                        <PlantillaReserva />
                        {/*<CustomDateRangePickerDay />*/}
                </body>
        )
}

export default ReservaPage
